<template>
	<BaseEditControls
		:block-id="blockId"
		:block-ref="blockRef"
		:button-title="$t('builder.editButton.title')"
		:current-tab="currentTab"
	>
		<ZyroPopupCard
			type="editor"
			:title="$t('builder.editButton.popupTitle')"
			:tabs="tabs"
			:current-tab="currentTab"
			@change="currentTab = $event"
			@close="$emit('close')"
		>
			<Component :is="currentTab.component" />
		</ZyroPopupCard>
	</BaseEditControls>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseEditControls from '@/components/builder-controls/BaseEditControls.vue';
import i18n from '@/i18n/setup';

import EditButtonTabAction from './EditButtonTabAction.vue';
import EditButtonTabLayout from './EditButtonTabLayout.vue';
import EditButtonTabStripe from './EditButtonTabStripe.vue';
import EditButtonTabStyle from './EditButtonTabStyle.vue';

const TABS = [
	{
		title: 'Stripe',
		component: 'EditButtonTabStripe',
		id: 'stripe',
	},
	{
		title: i18n.t('common.general'),
		component: 'EditButtonTabAction',
	},
	{
		title: i18n.t('common.style'),
		component: 'EditButtonTabStyle',
	},
	{
		title: i18n.t('common.layout'),
		component: 'EditButtonTabLayout',
	},
];

export default {
	components: {
		BaseEditControls,
		EditButtonTabAction,
		EditButtonTabLayout,
		EditButtonTabStyle,
		EditButtonTabStripe,
	},
	props: {
		blockId: {
			type: String,
			required: true,
		},
		blockRef: {
			type: HTMLElement,
			required: true,
		},
	},
	data() {
		return { currentTab: TABS[0] };
	},
	computed: {
		...mapGetters(['currentElement']),
		isStripeButton() {
			return this.currentElement.type === 'GridStripeButton';
		},
		tabs() {
			if (this.isStripeButton) {
				return TABS;
			}

			return TABS.filter((tab) => tab?.id !== 'stripe');
		},
	},
	created() {
		[this.currentTab] = this.tabs;
	},

};
</script>
