<template>
	<LinkSettings
		:label="$t('builder.editButton.tabActionLabel')"
		:base-target="currentElement.settings.target || '_self'"
		:base-href="currentElement.settings.href"
		:base-content="currentElement.content"
		@settings-update="linkSettingsData = {
			...linkSettingsData,
			...$event
		}"
	/>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { cloneDeep } from '@/utils/object';

import LinkSettings from './LinkSettings.vue';

export default {
	components: { LinkSettings },
	data() {
		return {
			elementId: null,
			currentElementBeforeEdit: null,
			linkSettingsData: {
				href: '',
				isUrlValid: true,
				target: '',
				content: '',
			},
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapGetters(['currentElement']),
	},
	watch: {
		'linkSettingsData.content': function updateContent(newValue) {
			this.setElementData({ data: { content: newValue } });
		},
	},
	beforeMount() {
		this.elementId = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		const {
			href,
			target,
			isUrlValid,
		} = this.linkSettingsData;
		const parameters = {
			elementId: this.elementId,
			data: isUrlValid ? {
				settings: {
					href,
					target,
				},
			} : this.currentElementBeforeEdit,
		};

		this.setElementData(parameters);
		this.pushElementDataToHistory(
			{
				elementId: this.elementId,
				oldData: this.currentElementBeforeEdit,
			},
		);
	},
	methods: {
		...mapMutations([
			'pushElementDataToHistory',
			'setElementData',
		]),
	},
};
</script>
