<template>
	<div>
		<ZyroFieldText
			v-model="apiKey"
			:placeholder="$t('builder.editButton.stripe.publicApiKey')"
			:label="$t('builder.editButton.stripe.publicApiKey')"
		/>
		<ZyroLabel class="label">
			{{ $t('builder.editButton.stripe.paymentType') }}
		</ZyroLabel>
		<ZyroSegmentControl
			class="segment-control"
			:controls="PAYMENT_TYPES"
			:active-control="paymentType"
			@change="paymentType = $event"
		/>
		<ZyroFieldText
			v-model="priceId"
			:placeholder="$t('builder.editButton.stripe.priceId')"
			:label="$t('builder.editButton.stripe.priceId')"
		/>
		<ZyroLabel class="label">
			{{ $t('builder.editButton.stripe.successPage') }}
		</ZyroLabel>
		<ZyroDropdown
			:options="pages"
			:current.sync="successPage"
		/>
		<ZyroLabel class="label">
			{{ $t('builder.editButton.stripe.cancellationPage') }}
		</ZyroLabel>
		<ZyroDropdown
			:options="pages"
			:current.sync="cancellationPage"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import i18n from '@/i18n/setup';
import { capitalizeFirstLetter } from '@/utils/modifyString';
import { cloneDeep } from '@/utils/object';

const PAYMENT_TYPES = [
	{
		title: i18n.t('builder.editButton.stripe.paymentPayment'),
		value: 'payment',
	},
	{
		title: i18n.t('builder.editButton.stripe.paymentSubscription'),
		value: 'subscription',
	},
];

export default {
	setup() {
		return { PAYMENT_TYPES };
	},
	data() {
		return {
			id: '',
			currentElementBeforeEdit: null,
		};
	},
	computed: {
		...mapGetters('pages', ['defaultPages']),
		...mapState([
			'currentElementId',
			'website',
		]),
		...mapGetters(['currentElement']),
		pages() {
			return Object.entries(this.defaultPages).map(([, page]) => ({
				path: page.path,
				title: page.name
					|| capitalizeFirstLetter(page.path.slice(1))
					|| 'Home',
			}));
		},
		paymentType: {
			get() {
				const currentType = this.currentElement.settings?.paymentType || 'payment';
				const paymentObject = this.PAYMENT_TYPES.find((payment) => payment.value === currentType);

				return paymentObject;
			},
			set(payment) {
				this.setElementData({ data: { settings: { paymentType: payment.value } } });
			},
		},
		successPage: {
			get() {
				const currentUrl = this.currentElement.settings?.successPageUrl || '/';
				const pageObject = this.pages.find((page) => page.path === currentUrl);

				return pageObject || this.pages[0];
			},
			set(page) {
				this.setElementData({ data: { settings: { successPageUrl: page.path } } });
			},
		},
		cancellationPage: {
			get() {
				const currentUrl = this.currentElement.settings?.cancellationPageUrl || '/';
				const pageObject = this.pages.find((page) => page.path === currentUrl);

				return pageObject || this.pages[0];
			},
			set(page) {
				this.setElementData({ data: { settings: { cancellationPageUrl: page.path } } });
			},
		},
		priceId: {
			get() {
				return this.currentElement.settings?.priceId || '';
			},
			set(event) {
				this.setElementData({ data: { settings: { priceId: event.target.value } } });
			},
		},
		apiKey: {
			get() {
				return this.website.meta?.stripePublicApiKey || '';
			},
			set(event) {
				this.setWebsiteMeta({
					key: 'stripePublicApiKey',
					value: event.target.value,
				});
			},
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: {
		...mapMutations([
			'pushElementDataToHistory',
			'setElementData',
			'setWebsiteMeta',
		]),
	},
};
</script>
<style lang="scss" scoped>
.label,
.segment-control {
	margin-bottom: 8px;
}
</style>
