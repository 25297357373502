<template>
	<div class="style">
		<ZyroLabel>
			{{ $t('builder.editButton.tabStyleLabel') }}
		</ZyroLabel>
		<div class="style__content">
			<div
				v-for="button in buttonTypes"
				:key="button.content"
				class="style__content-item"
				:class="{ 'style__content-item--active': button.type === buttonType }"
				@click="buttonType = button.type"
			>
				<GridButton
					:content="button.content"
					:type="button.type"
					@click.prevent
				/>
			</div>

			<div
				class="style__controler-container"
			>
				<ZyroLabel>
					{{ buttonLabel }}
				</ZyroLabel>
				<ZyroSegmentControl
					class="style__controler"
					:controls="filteredTabs"
					:active-control="currentTab"
					@change="changeTab"
				/>
				<ul
					class="style__controler-content"
				>
					<li class="style__controler-item">
						<ZyroLabel>{{ currentTab.text }}</ZyroLabel>
						<ZyroColorPicker
							:value="buttonCustomStyle"
							:default-mode="$options.COLOR_PICKER_MODE_FULL"
							@input="buttonCustomStyle = $event,
								buttonCustomStyleActive = $event,
								addHotjar = true"
						/>
					</li>
					<li class="style__controler-item">
						<ZyroLabel>{{ currentTab.textHover }}</ZyroLabel>
						<ZyroColorPicker
							:value="buttonCustomStyleHover"
							:default-mode="$options.COLOR_PICKER_MODE_FULL"
							@input="buttonCustomStyleHover = $event, addHotjar = true"
						/>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { COLOR_PICKER_MODE_FULL } from '@/components/global/zyro-color-picker/constants';
import {
	PROPERTY_BACKGROUND_COLOR,
	PROPERTY_BORDER_COLOR,
	PROPERTY_BORDER_WIDTH,
	PROPERTY_COLOR,
} from '@/constants/globalStyles';
import i18n from '@/i18n/setup';
import { cloneDeep } from '@/utils/object';
import GridButton from '@user/components/grid-components/button/GridButton.vue';

const tabs = [
	{
		id: PROPERTY_BACKGROUND_COLOR,
		title: i18n.t('builder.editButton.customButtonStyles.fill.title'),
		text: i18n.t('builder.editButton.customButtonStyles.fill.text'),
		textHover: i18n.t('builder.editButton.customButtonStyles.fill.textHover'),
	},
	{
		id: PROPERTY_COLOR,
		title: i18n.t('builder.editButton.customButtonStyles.text.title'),
		text: i18n.t('builder.editButton.customButtonStyles.text.text'),
		textHover: i18n.t('builder.editButton.customButtonStyles.text.textHover'),
	},
	{
		id: PROPERTY_BORDER_COLOR,
		title: i18n.t('builder.editButton.customButtonStyles.border.title'),
		text: i18n.t('builder.editButton.customButtonStyles.border.text'),
		textHover: i18n.t('builder.editButton.customButtonStyles.border.textHover'),
	},
];

export default {
	tabs,
	COLOR_PICKER_MODE_FULL,
	components: { GridButton },
	data() {
		return {
			id: '',
			currentTab: this.$options.tabs['0'],
			currentElementBeforeEdit: null,
			addHotjar: false,
			buttonTypes: {
				primary: {
					content: i18n.t('common.primary'),
					type: 'primary',
				},
				secondary: {
					content: i18n.t('common.secondary'),
					type: 'secondary',
				},
			},
		};
	},
	computed: {
		...mapState([
			'currentElementId',
			'website',
		]),
		...mapState('user', ['user']),
		...mapGetters(['currentElement']),
		buttonLabel() {
			return this.$t(`builder.editButton.${this.currentElement.settings.type === 'primary' ? 'primaryButtonStyles' : 'secondaryButtonStyles'}`);
		},
		filteredTabs() {
			// If there is no border, color-border tab should be hidden

			return tabs.filter((tab) => (!this.hasBorder || tab.id !== PROPERTY_BORDER_COLOR));
		},
		buttonType: {
			get() {
				return this.currentElement.settings.type;
			},
			set(newValue) {
				this.setElementData({ data: { settings: { type: newValue } } });
			},
		},
		hasBorder() {
			const border = this.website.styles[`grid-button-${this.currentElement.settings.type}`][PROPERTY_BORDER_WIDTH];

			return [
				'0',
				'0px',
			].includes(border);
		},
		buttonCustomStyle: {
			get() {
				const customStyleProperty = this.currentElement.settings.styles[
					`grid-button-${this.currentElement.settings.type}-${this.currentTab.id}`
				];

				if (customStyleProperty) {
					return customStyleProperty;
				}

				return this.website.styles[`grid-button-${this.currentElement.settings.type}`][`${this.currentTab.id}`];
			},
			set(newValue) {
				this.setElementData({
					data: {
						settings: {
							styles: {
								[
								`grid-button-${this.currentElement.settings.type}-${this.currentTab.id}`
								]: newValue,
							},
						},
					},
				});
			},
		},
		buttonCustomStyleHover: {
			get() {
				const customStylePropertyHover = this.currentElement.settings.styles[
					`grid-button-${this.currentElement.settings.type}-${this.currentTab.id}-hover`
				];

				if (customStylePropertyHover) {
					return customStylePropertyHover;
				}

				return this.website.styles[`grid-button-${this.currentElement.settings.type}`][`${this.currentTab.id}-hover`];
			},
			set(newValue) {
				this.setElementData({
					data: {
						settings: {
							styles: {
								[
								`grid-button-${this.currentElement.settings.type}-${this.currentTab.id}-hover`
								]: newValue,
							},
						},
					},
				});
			},
		},
		buttonCustomStyleActive: {
			get() {
				return this.website.styles[`grid-button-${this.currentElement.settings.type}`][`${this.currentTab.id}-active`];
			},
			set(newValue) {
				this.setElementData({
					data: {
						settings: {
							styles: {
								[
								`grid-button-${this.currentElement.settings.type}-${this.currentTab.id}-active`
								]: newValue,
							},
						},
					},
				});
			},
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
		this.addHotjarAtribute();
	},
	methods: {
		...mapMutations([
			'pushElementDataToHistory',
			'setElementData',
			'setStyleProperty',
		]),

		addHotjarAtribute() {
			if (this.addHotjar) {
				window.hj('identify', this.user.id, { changedButtonStyle: true });
			}
		},

		changeTab(value) {
			this.currentTab = value;
		},
	},
};
</script>

<style lang="scss" scoped>
.style {
	&__content {
		&-item {
			display: flex;
			justify-content: center;
			padding: 8px 0;
			margin: 8px 0;
			background: $light;
			border: 1px solid $grey-200;
			border-radius: 3px;

			&--active {
				background: $grey-100;
				border: 1px solid $grey-600;
			}

			&:hover {
				border: 1px solid $grey-600;
			}
		}
	}

	&__controler {
		margin: 8px 0 16px;

		&-container {
			margin-top: 16px;
		}

		&-content {
			margin: 8px 0 32px;
			list-style: none;
		}

		&-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 16px 0 8px;
			border-bottom: 1px solid $grey-100;
		}
	}
}
</style>
