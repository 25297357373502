<template>
	<div class="layout">
		<ZyroLabel class="layout__alignment-label">
			{{ $t('builder.editButton.tabLayoutLabel') }}
		</ZyroLabel>

		<div class="layout__alignment">
			<ZyroIconControls
				:current.sync="align"
				:icons="$options.iconsAlign"
			/>
			<ZyroIconControls
				:current.sync="justify"
				:icons="$options.iconsJustify"
				:toggleable="false"
			/>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { cloneDeep } from '@/utils/object';

const iconsAlign = [
	{
		value: 'flex-start',
		icon: 'align-left',
	},
	{
		value: 'center',
		icon: 'align-center',
	},
	{
		value: 'flex-end',
		icon: 'align-right',
	},
];
const iconsJustify = [
	{
		value: 'flex-start',
		icon: 'align-top',
	},
	{
		value: 'center',
		icon: 'align-middle',
	},
	{
		value: 'flex-end',
		icon: 'align-bottom',
	},
];

export default {
	iconsAlign,
	iconsJustify,
	data() {
		return {
			id: '',
			currentElementBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapGetters(['currentElement']),
		align: {
			get() {
				return this.currentElement.settings.styles.align;
			},
			set(newValue) {
				this.setElementData({ data: { settings: { styles: { align: newValue } } } });
			},
		},
		justify: {
			get() {
				return this.currentElement.settings.styles.justify;
			},
			set(newValue) {
				this.setElementData({ data: { settings: { styles: { justify: newValue } } } });
			},
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: {
		...mapMutations([
			'pushElementDataToHistory',
			'setElementData',
		]),
	},
};
</script>

<style lang="scss" scoped>
.layout {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__alignment {
		display: flex;

		> *:not(:last-child) {
			margin-right: 24px;
		}

		&-label {
			margin-top: 16px;
		}
	}
}
</style>
